/* Fix conflicting widths */
.profile-card {
  padding: 20px;
  min-width: 300px; /* Adjusted to align with max-width */
  max-width: 400px; /* Ensure this is >= min-width */
  text-align: left;
  background-color: #ffffff; /* Keep the card's background white */
  margin: 2px auto; /* Add space between elements */
  position: relative; /* Avoid using absolute unless necessary */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Uniform shadow */
}

/* Ensure gap in flex container */
.mdb-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 20px; /* Add gap between cards */
}

/* Ensure responsiveness */
@media (max-width: 768px) {
  .mdb-container {
    flex-direction: column;
    gap: 10px; /* Reduce gap for smaller screens */
  }
  .profile-card {
    max-width: 100%; /* Make the card take full width */
    margin: 10px; /* Add spacing for small screens */
  }
}

/* Prevent overlapping from transform */
.profile-card:hover {
  transform: translateY(-5px); /* Smooth hover effect */
  margin-bottom: 10px; /* Add bottom margin to prevent overlap */
}

/* Fix for absolute positioning in element.style */
.element.style {
  position: relative; /* Change to relative for normal flow */
  transform: none; /* Remove translate3d to avoid collision */
  margin-top: 20px; /* Add spacing if necessary */
}

/* Ensure no collision in .mdb-row */
.mdb-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between columns */
  justify-content: center; /* Center content */
}
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.2s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}