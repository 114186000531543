
.login-container {
    margin-top: 37px;
    margin-right: 100px;
    padding: 2rem 1.5rem;
    border-radius: 0.5rem;
    background: #fff;
    width: 75%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);   
  }
  
  .login-container .form-title {
    font-size: 1.37rem;
    font-weight: 600;
    margin-bottom: 1.87rem;
  }
  
  .login-container .social-login {
    display: flex;
    gap: 1.31rem;
  }
  .login-form .login-button {
    border: none;
    outline: none;
    width: 100%;
    height: 54px;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 0.31rem;
    background: var(--accent-color) !important;
  
    transition: 0.3s ease;
  }
  
  .social-login .social-button {
    display: flex;
    gap: 0.81rem;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 0.75rem 0;
    border-radius: 0.31rem;
    align-items: center;
    justify-content: center;
    background: var(--accent-color) !important;
    border: 1px solid #d5cbff;
    transition: 0.3s ease;
  }

  .social-login .social-button .social-icon {
    width: 23px;
  }
  
  .login-container .separator {
    position: relative;
    margin: 1.5rem 0;
    text-align: center;
    background: #fff;
  }
  
  .login-container .separator span {
    z-index: 1;
    font-weight: 500;
    color: #6652be;
    position: relative;
    background: #fff;
    font-size: 1.06rem;
    padding: 0 0.9rem;
  }
  
  .login-container .separator::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    width: 100%;
    background: #bfb3f2;
  }
  
  .login-form .input-wrapper {
    height: 54px;
    width: 100%;
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .login-form .input-field {
    width: 100%;
    height: 100%;
    outline: none;
    font-size: 1.06rem;
    border-radius: 0.31rem;
    border: 1px solid #bfb3f2;
    padding: 0px 1.25rem 0 3.12rem;
    transition: 0.2s ease;
  }
  
  .login-form .input-field:focus {
    border-color: #5f41e4;
  }
  
  .login-form .input-field::placeholder {
    color: #9284c8;
  }
  
  .login-form .input-wrapper i {
    position: absolute;
    top: 50%;
    height: 100%;
    display: flex;
    color: #a395e0;
    align-items: center;
    transform: translateY(-50%);
    transition: 0.2s ease;
  }
  
  .login-form .input-wrapper i:not(.eye-icon) {
    left: 0.93rem;
    pointer-events: none;
  }
  
  .login-form .input-field:focus ~ i:not(.eye-icon) {
    color: #5f41e4;
  }
 
  .login-form .input-wrapper i.eye-icon {
    display: none;
    right: 0.93rem;
    color: #917de8;
    cursor: pointer;
    font-size: 1.25rem;
  }
  
  .login-form .input-wrapper .input-field:valid ~ .eye-icon {
    display: flex;
  }
  
  .login-form .forgot-password-link {
    display: block;
    width: fit-content;
    margin-top: -0.44rem;
  }
  
  .login-form .login-button {
    border: none;
    outline: none;
    width: 100%;
    height: 54px;
    color: #fff;
    font-size: 1.125rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 0.31rem;
    background: #5f41e4;
    transition: 0.3s ease;
  }
  
  .login-form .login-button:hover {
    background: #4320df;
  }
  
  .login-container .signup-prompt {
    text-align: center;
    font-size: 1.06rem;
    font-weight: 500;
  }
  
  .login-container a {
    color: #5f41e4;
    font-weight: 500;
    text-decoration: none;
  }
  
  .login-container a:hover {
    text-decoration: underline;
  }
  .navmenu >ul{
    font-Weight: "bolder",
  }
  .para-one{
    margin-top:10px ;
  }
  
  .testimonial-container{
    margin-top: 10px;
  }
  
  
  
  