/* body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
  }
  
  .homepage {
    color: #333;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 50px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 1.8rem;
    font-weight: bold;
    color: #6a1b9a;
  }
  
  .nav a {
    margin: 0 15px;
    text-decoration: none;
    color: #555;
    font-size: 1rem;
  }
  
  .register-btn {
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .main-content {
    padding: 50px;
  }
  
  .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-section {
    max-width: 50%;
  }
  
  .title {
    font-size: 2.8rem;
    color: #6a1b9a;
    margin-bottom: 15px;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #555;
  }
  
  .description {
    margin: 20px 0;
    color: #666;
    line-height: 1.5;
  }
  
  .learn-more-btn {
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 10px 25px;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .right-section .image-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .image-item img {
    width: 100%;
    height: auto;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .features-section {
    text-align: center;
    margin: 50px 0;
  }
  
  .features-title {
    font-size: 2.4rem;
    margin-bottom: 30px;
  }
  
  .features-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .feature-item {
    max-width: 300px;
    text-align: center;
  }
  
  .feature-item img {
    width: 100px;
    margin-bottom: 15px;
  }
  
  .cta-section {
    text-align: center;
    padding: 50px 20px;
    background-color: #6a1b9a;
    color: white;
  }
  
  .cta-section h2 {
    font-size: 2.2rem;
  }
  
  .cta-section p {
    margin: 20px 0;
  }
  
  .register-now-btn {
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 25px;
    cursor: pointer;
  }
  
  .footer {
    text-align: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  .footer-nav a {
    margin: 0 10px;
    text-decoration: none;
    color: #ff9800;
  }
   */



   /* body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .homepage {
    text-align: center;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav a {
    margin: 0 15px;
    text-decoration: none;
    color: black;
    font-size: 16px;
  }
  
  .contact-btn {
    padding: 10px 20px;
    background-color: white;
    border: 2px solid black;
    color: black;
    font-size: 16px;
    cursor: pointer;
  }
  
  .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
    background-image: url('https://cdn.b12.io/client_media/vAVWhJoC/ada167b6-b84d-11ef-a12c-0242ac110002-jpg-hero_image.jpeg');
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
  }
  
  .hero-text {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    margin-left: 5%;
  }
  
  .hero-text .hero-title {
    font-size: 48px;
    margin: 0;
  }
  
  .hero-text .hero-subtitle {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .view-services-btn {
    padding: 10px 20px;
    background-color: green;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .hero-images {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    margin-right: 5%;
    justify-content: space-between;
  }
  
  .hero-image {
    width: 48%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .features-section {
    padding: 40px 20px;
  }
  
  .features-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature-item {
    text-align: center;
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  .feature-item img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .cta-section {
    background-color: #f8f9fa;
    padding: 40px 20px;
  }
  
  .register-now-btn {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
  }
  
  .footer-nav a {
    margin: 0 15px;
    text-decoration: none;
    color: white;
  }
   */


   body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .homepage {
    text-align: center;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav a {
    margin: 0 15px;
    text-decoration: none;
    color: black;
    font-size: 16px;
  }
  
  .contact-btn {
    padding: 10px 20px;
    background-color: white;
    border: 2px solid black;
    color: black;
    font-size: 16px;
    cursor: pointer;
  }
  
  .hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-image: url('https://cdn.b12.io/client_media/vAVWhJoC/ada167b6-b84d-11ef-a12c-0242ac110002-jpg-hero_image.jpeg'); 
    background-size: cover;
    background-position: center;
    position: relative;
    color: white;
  }
  
  .hero-text {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
  }
  
  .hero-text .hero-title {
    font-size: 48px;
    margin: 0;
  }
  
  .hero-text .hero-subtitle {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .sales-section {
    padding: 40px 20px;
  }
  
  .sales-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .sales-item {
    text-align: center;
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  .sales-item img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .team-section {
    padding: 40px 20px;
  }
  
  .team-image {
    width: 100%;
    max-width: 800px;
    margin-top: 20px;
    border-radius: 10px;
  }
  
  .footer {
    background-color: #333;
    color: white;
    padding: 20px 0;
  }
  
  .footer-nav {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-nav a {
    text-decoration: none;
    color: white;
  }
  
  .cta {
    padding: 40px 20px;
  }
  
  .get-in-touch-btn {
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  