.bulk-insert-page {
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  
  .upload-section {
    margin-bottom: 20px;
  }
  
  .manual-entry {
    margin-bottom: 20px;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .data-table input {
    width: 100%;
    padding: 5px;
  }
  
  .action-buttons {
    text-align: center;
  }
  
  .error-section {
    color: red;
  }
  