section, .section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 98px; 
}
.contact .php-email-form {
  background-color: var(--surface-color);
  height: 90.9%;
  padding: 30px;
  border-top: 3px solid rgb(255, 255, 255);
  border-bottom: 3px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}


.contact .info-item {
  background: 
  color-mix(in srgb, var(--default-color), transparent 96%);
  padding: 30px;
} 
.row [input] {
--bs-gutter-x: 1.5rem;
--bs-gutter-y: 0;
display: flex;
flex-wrap: wrap;
margin-top: calc(-1* var(--bs-gutter-y));
margin-right: calc(-.5* var(--bs-gutter-x));
margin-left: calc(-.5* var(--bs-gutter-x));
} 

form {
display: block;
margin-top: 0em;
unicode-bidi: isolate;
padding-bottom: 5px;
} 
